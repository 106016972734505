<template>
  <div class="login p-grid nested-grid p-ai-center p-m-0">
    <Card class="p-mx-auto p-col-8 p-md-4 p-lg-3 p-xl-3">
      <template #title>
        <div class="p-text-center">Вход</div>
      </template>
      <template #content>
        <div class="p-fluid p-grid">
          <div class="p-field p-col-12 p-m-0 p-pb-0">
            <span class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-user"></i>
              </span>
              <span class="p-float-label">
                <InputMask
                  id="login"
                  v-model="login"
                  mask="+7 (999) 999-99-99"
                  :autoClear="false"
                  :autofocus="true"
                  @focus="inputMaskFix"
                  @keydown.enter="onLoginReady"
                  :disabled="isDialogMustBeOpened"
                  v-tooltip.focus="'Например: +7 (123) 456-78-90'"
                />
                <label for="login">Телефон</label>
              </span>
            </span>
          </div>
        </div>
      </template>
      <template #footer>
        <Button
          class="p-col-12"
          @click="onLoginReady"
          :disabled="!isLoginValid || isDialogMustBeOpened"
          label="Далее"
          v-tooltip.right="
            'Вам будет отправлено СМС сообщение с кодом подтверждения'
          "
        />
      </template>
    </Card>
    <Dialog
      v-model:visible="isDialogMustBeOpened"
      :dismissableMask="true"
      :modal="true"
      @hide="onLoginCodeDialogDismiss"
      class="p-col-11 p-md-6 p-lg-5 p-xl-4 p-p-0"
      :autoZIndex="false"
    >
      <template #header><h2 class="p-m-0">Подтверждение входа</h2></template>

      На ваш телефонный номер было отправлено
      <b>СМС сообщение с кодом подтверждения</b>. Введите код в поле ниже, чтобы
      продолжить.

      <template #footer>
        <div class="p-grid">
          <div class="p-col-12 p-md-6 p-fluid">
            <div class="p-field p-m-0">
              <div class="p-float-label">
                <InputMask
                  id="code"
                  v-model="code"
                  mask="9 9 9 9 9 9"
                  :autoClear="false"
                  @focus="inputMaskFix"
                  @keydown.enter="onLoginCodeReady"
                  ref="codeField"
                  v-tooltip.focus.bottom="'Например: 6 3 7 2 1 0'"
                />
                <label for="code">Код подтверждения</label>
              </div>
            </div>
          </div>
          <div class="p-col-12 p-md-6 p-fluid">
            <Button
              label="Войти"
              @click="onLoginCodeReady"
              :disabled="!isCodeValid || isCodeButtonDisabled"
              style="width: 100%"
            />
          </div>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import API from "../API";
// import { Toast } from "../internals/SweetAlerts";
import InputMask from "primevue/inputmask";
import Button from "primevue/button";
import Card from "primevue/card";
import Dialog from "primevue/dialog";

export default {
  name: "login",
  components: {
    InputMask,
    Button,
    Card,
    Dialog,
  },
  data: () => {
    return {
      login: "",
      code: "",
      isDialogMustBeOpened: false,
      isCodeButtonDisabled: false,
    };
  },
  computed: {
    cleanedLogin() {
      // remove all non-numeric characters
      return this.login.replace(/[^0-9]/g, "");
    },
    isLoginValid() {
      return this.cleanedLogin.length === 11;
    },
    cleanedCode() {
      return this.code.replace(/[^0-9]/g, "");
    },
    isCodeValid() {
      return this.cleanedCode.length === 6;
    },
  },
  watch: {
    isDialogMustBeOpened(value) {
      if (value) {
        setTimeout(() => {
          this.$refs.codeField.$el.focus();
        }, 150);
      }
    },
  },
  methods: {
    inputMaskFix(event) {
      event.target.value = (event.target.value || "") + " ";
    },
    onLoginReady() {
      if (!this.isLoginValid) {
        this.$toast.add({
          severity: "warn",
          summary: "Ошибка!",
          detail: "Телефон не задан",
          life: 5000,
        });
        // Toast.fire({
        //   icon: "warning",
        //   title: "Телефон не задан",
        // });
        return;
      }

      // send SMS request to server (do not wait for success/failure)
      API.auth_requestCodeSending(this.cleanedLogin);
      // show popup
      this.isDialogMustBeOpened = true;
      return;
    },
    onLoginCodeReady() {
      if (!this.isCodeValid) {
        this.$toast.add({
          severity: "warn",
          summary: "Ошибка!",
          detail: "Код не корректен",
          life: 5000,
        });
        // Toast.fire({
        //   icon: "warning",
        //   title: "Код не корректен",
        // });
        return;
      }
      this.isCodeButtonDisabled = true;
      API.auth_sendCode(this.cleanedLogin, this.cleanedCode)
        .then(this.onLoginCodeStatusReceived)
        .catch(this.onLoginCodeStatusReceived);
    },
    onLoginCodeStatusReceived(result) {
      if (result?.status === "success") {
        this.onLoginCodeAccepted(result);
      } else {
        this.onLoginCodeRejected(result);
        console.error(result);
      }
      this.isCodeButtonDisabled = false;
    },
    onLoginCodeAccepted() {
      this.$toast.add({
        severity: "success",
        summary: "Вход произведен успешно!",
        detail: "",
        life: 5000,
      });
      // Toast.fire({
      //   icon: "success",
      //   title: "Вход произведен успешно!",
      // });
      this.$router.push({ name: "dashboard" });
    },
    onLoginCodeRejected() {
      // Toast.fire({
      //   icon: "warning",
      //   title: "Код подтверждения неверен! Попробуйте снова.",
      // });
      this.$toast.add({
        severity: "warn",
        summary: "Ошибка!",
        detail: "Код подтверждения неверен! Попробуйте снова.",
        life: 5000,
        baseZIndex: 99000,
      });
    },
    onLoginCodeDialogDismiss() {
      console.log(this);
      this.code = "";
      this.isDialogMustBeOpened = false;
      this.isCodeButtonDisabled = false;
    },
  },
  mounted() {
    if (API.isTokenAlive()) {
      this.$router.push({ name: "dashboard" });
    }
  },
};
</script>

<style>
.login {
  background: #ccc;
  width: 100%;
  height: 100%;
}
</style>
